window.errorMessage = 'There was an error. Try again later or contact our support.'

window.yearsOptions = {}
window.currentYear = (new Date()).getFullYear()
for(let i = window.currentYear - 80; i <= window.currentYear; i++)
  window.yearsOptions[i] = i

window.customerStatuses = ['New', 'Pending', 'Complete', 'Denied', 'Archived']
window.profileStatuses = ['Complete', 'Active', 'Restricted', 'Suspended', 'Dormant', 'Closed']
window.accountStatuses = ['Open', 'Pending', 'Closed', 'Dormant']

window.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

window.currencyOptions = [
  {
    id: 'USD',
    text: 'USD'
  },
  {
    id: 'EUR',
    text: 'EUR',
    disabled: true
  }
]

window.countries = [
  {
    id: "CA",
    text: "Canada"
  },
  {
    id: "US",
    text: "United States"
  },
  {
    id: "AF",
    text: "Afghanistan"
  },
  {
    id: "AX",
    text: "Åland Islands"
  },
  {
    id: "AL",
    text: "Albania"
  },
  {
    id: "DZ",
    text: "Algeria"
  },
  {
    id: "AS",
    text: "American Samoa"
  },
  {
    id: "AD",
    text: "Andorra"
  },
  {
    id: "AO",
    text: "Angola"
  },
  {
    id: "AI",
    text: "Anguilla"
  },
  {
    id: "AQ",
    text: "Antarctica"
  },
  {
    id: "AG",
    text: "Antigua and Barbuda"
  },
  {
    id: "AR",
    text: "Argentina"
  },
  {
    id: "AM",
    text: "Armenia"
  },
  {
    id: "AW",
    text: "Aruba"
  },
  {
    id: "AU",
    text: "Australia"
  },
  {
    id: "AT",
    text: "Austria"
  },
  {
    id: "AZ",
    text: "Azerbaijan"
  },
  {
    id: "BH",
    text: "Bahrain"
  },
  {
    id: "BS",
    text: "Bahamas"
  },
  {
    id: "BD",
    text: "Bangladesh"
  },
  {
    id: "BB",
    text: "Barbados"
  },
  {
    id: "BY",
    text: "Belarus"
  },
  {
    id: "BE",
    text: "Belgium"
  },
  {
    id: "BZ",
    text: "Belize"
  },
  {
    id: "BJ",
    text: "Benin"
  },
  {
    id: "BM",
    text: "Bermuda"
  },
  {
    id: "BT",
    text: "Bhutan"
  },
  {
    id: "BO",
    text: "Bolivia, Plurinational State of"
  },
  {
    id: "BQ",
    text: "Bonaire, Sint Eustatius and Saba"
  },
  {
    id: "BA",
    text: "Bosnia and Herzegovina"
  },
  {
    id: "BW",
    text: "Botswana"
  },
  {
    id: "BV",
    text: "Bouvet Island"
  },
  {
    id: "BR",
    text: "Brazil"
  },
  {
    id: "IO",
    text: "British Indian Ocean Territory"
  },
  {
    id: "BN",
    text: "Brunei Darussalam"
  },
  {
    id: "BG",
    text: "Bulgaria"
  },
  {
    id: "BF",
    text: "Burkina Faso"
  },
  {
    id: "BI",
    text: "Burundi"
  },
  {
    id: "KH",
    text: "Cambodia"
  },
  {
    id: "CM",
    text: "Cameroon"
  },
  {
    id: "CV",
    text: "Cape Verde"
  },
  {
    id: "KY",
    text: "Cayman Islands"
  },
  {
    id: "CF",
    text: "Central African Republic"
  },
  {
    id: "TD",
    text: "Chad"
  },
  {
    id: "CL",
    text: "Chile"
  },
  {
    id: "CN",
    text: "China"
  },
  {
    id: "CX",
    text: "Christmas Island"
  },
  {
    id: "CC",
    text: "Cocos (Keeling) Islands"
  },
  {
    id: "CO",
    text: "Colombia"
  },
  {
    id: "KM",
    text: "Comoros"
  },
  {
    id: "CG",
    text: "Congo"
  },
  {
    id: "CD",
    text: "Congo, the Democratic Republic of the"
  },
  {
    id: "CK",
    text: "Cook Islands"
  },
  {
    id: "CR",
    text: "Costa Rica"
  },
  {
    id: "CI",
    text: "Côte d&#39;Ivoire"
  },
  {
    id: "HR",
    text: "Croatia"
  },
  {
    id: "CU",
    text: "Cuba"
  },
  {
    id: "CW",
    text: "Curaçao"
  },
  {
    id: "CY",
    text: "Cyprus"
  },
  {
    id: "CZ",
    text: "Czech Republic"
  },
  {
    id: "DK",
    text: "Denmark"
  },
  {
    id: "DJ",
    text: "Djibouti"
  },
  {
    id: "DM",
    text: "Dominica"
  },
  {
    id: "DO",
    text: "Dominican Republic"
  },
  {
    id: "EC",
    text: "Ecuador"
  },
  {
    id: "EG",
    text: "Egypt"
  },
  {
    id: "SV",
    text: "El Salvador"
  },
  {
    id: "GQ",
    text: "Equatorial Guinea"
  },
  {
    id: "ER",
    text: "Eritrea"
  },
  {
    id: "EE",
    text: "Estonia"
  },
  {
    id: "ET",
    text: "Ethiopia"
  },
  {
    id: "FK",
    text: "Falkland Islands (Malvinas)"
  },
  {
    id: "FO",
    text: "Faroe Islands"
  },
  {
    id: "FJ",
    text: "Fiji"
  },
  {
    id: "FI",
    text: "Finland"
  },
  {
    id: "FR",
    text: "France"
  },
  {
    id: "GF",
    text: "French Guiana"
  },
  {
    id: "PF",
    text: "French Polynesia"
  },
  {
    id: "TF",
    text: "French Southern Territories"
  },
  {
    id: "GA",
    text: "Gabon"
  },
  {
    id: "GM",
    text: "Gambia"
  },
  {
    id: "GE",
    text: "Georgia"
  },
  {
    id: "DE",
    text: "Germany"
  },
  {
    id: "GH",
    text: "Ghana"
  },
  {
    id: "GI",
    text: "Gibraltar"
  },
  {
    id: "GR",
    text: "Greece"
  },
  {
    id: "GL",
    text: "Greenland"
  },
  {
    id: "GD",
    text: "Grenada"
  },
  {
    id: "GP",
    text: "Guadeloupe"
  },
  {
    id: "GU",
    text: "Guam"
  },
  {
    id: "GT",
    text: "Guatemala"
  },
  {
    id: "GG",
    text: "Guernsey"
  },
  {
    id: "GN",
    text: "Guinea"
  },
  {
    id: "GW",
    text: "Guinea-Bissau"
  },
  {
    id: "GY",
    text: "Guyana"
  },
  {
    id: "HT",
    text: "Haiti"
  },
  {
    id: "HM",
    text: "Heard Island and McDonald Islands"
  },
  {
    id: "VA",
    text: "Holy See (Vatican City State)"
  },
  {
    id: "HN",
    text: "Honduras"
  },
  {
    id: "HK",
    text: "Hong Kong"
  },
  {
    id: "HU",
    text: "Hungary"
  },
  {
    id: "IS",
    text: "Iceland"
  },
  {
    id: "IN",
    text: "India"
  },
  {
    id: "ID",
    text: "Indonesia"
  },
  {
    id: "IR",
    text: "Iran, Islamic Republic of"
  },
  {
    id: "IQ",
    text: "Iraq"
  },
  {
    id: "IE",
    text: "Ireland"
  },
  {
    id: "IM",
    text: "Isle of Man"
  },
  {
    id: "IL",
    text: "Israel"
  },
  {
    id: "IT",
    text: "Italy"
  },
  {
    id: "JM",
    text: "Jamaica"
  },
  {
    id: "JP",
    text: "Japan"
  },
  {
    id: "JE",
    text: "Jersey"
  },
  {
    id: "JO",
    text: "Jordan"
  },
  {
    id: "KZ",
    text: "Kazakhstan"
  },
  {
    id: "KE",
    text: "Kenya"
  },
  {
    id: "KI",
    text: "Kiribati"
  },
  {
    id: "KP",
    text: "Korea, Democratic People&#39;s Republic of"
  },
  {
    id: "KR",
    text: "Korea, Republic of"
  },
  {
    id: "KW",
    text: "Kuwait"
  },
  {
    id: "KG",
    text: "Kyrgyzstan"
  },
  {
    id: "LA",
    text: "Lao People&#39;s Democratic Republic"
  },
  {
    id: "LV",
    text: "Latvia"
  },
  {
    id: "LB",
    text: "Lebanon"
  },
  {
    id: "LS",
    text: "Lesotho"
  },
  {
    id: "LR",
    text: "Liberia"
  },
  {
    id: "LY",
    text: "Libya"
  },
  {
    id: "LI",
    text: "Liechtenstein"
  },
  {
    id: "LT",
    text: "Lithuania"
  },
  {
    id: "LU",
    text: "Luxembourg"
  },
  {
    id: "MO",
    text: "Macao"
  },
  {
    id: "MK",
    text: "Macedonia, the Former Yugoslav Republic of"
  },
  {
    id: "MG",
    text: "Madagascar"
  },
  {
    id: "MW",
    text: "Malawi"
  },
  {
    id: "MY",
    text: "Malaysia"
  },
  {
    id: "MV",
    text: "Maldives"
  },
  {
    id: "ML",
    text: "Mali"
  },
  {
    id: "MT",
    text: "Malta"
  },
  {
    id: "MH",
    text: "Marshall Islands"
  },
  {
    id: "MQ",
    text: "Martinique"
  },
  {
    id: "MR",
    text: "Mauritania"
  },
  {
    id: "MU",
    text: "Mauritius"
  },
  {
    id: "YT",
    text: "Mayotte"
  },
  {
    id: "MX",
    text: "Mexico"
  },
  {
    id: "FM",
    text: "Micronesia, Federated States of"
  },
  {
    id: "MD",
    text: "Moldova, Republic of"
  },
  {
    id: "MC",
    text: "Monaco"
  },
  {
    id: "MN",
    text: "Mongolia"
  },
  {
    id: "ME",
    text: "Montenegro"
  },
  {
    id: "MS",
    text: "Montserrat"
  },
  {
    id: "MA",
    text: "Morocco"
  },
  {
    id: "MZ",
    text: "Mozambique"
  },
  {
    id: "MM",
    text: "Myanmar"
  },
  {
    id: "NA",
    text: "Namibia"
  },
  {
    id: "NR",
    text: "Nauru"
  },
  {
    id: "NP",
    text: "Nepal"
  },
  {
    id: "NL",
    text: "Netherlands"
  },
  {
    id: "NC",
    text: "New Caledonia"
  },
  {
    id: "NZ",
    text: "New Zealand"
  },
  {
    id: "NI",
    text: "Nicaragua"
  },
  {
    id: "NE",
    text: "Niger"
  },
  {
    id: "NG",
    text: "Nigeria"
  },
  {
    id: "NU",
    text: "Niue"
  },
  {
    id: "NF",
    text: "Norfolk Island"
  },
  {
    id: "MP",
    text: "Northern Mariana Islands"
  },
  {
    id: "NO",
    text: "Norway"
  },
  {
    id: "OM",
    text: "Oman"
  },
  {
    id: "PK",
    text: "Pakistan"
  },
  {
    id: "PW",
    text: "Palau"
  },
  {
    id: "PS",
    text: "Palestine, State of"
  },
  {
    id: "PA",
    text: "Panama"
  },
  {
    id: "PG",
    text: "Papua New Guinea"
  },
  {
    id: "PY",
    text: "Paraguay"
  },
  {
    id: "PE",
    text: "Peru"
  },
  {
    id: "PH",
    text: "Philippines"
  },
  {
    id: "PN",
    text: "Pitcairn"
  },
  {
    id: "PL",
    text: "Poland"
  },
  {
    id: "PT",
    text: "Portugal"
  },
  {
    id: "PR",
    text: "Puerto Rico"
  },
  {
    id: "QA",
    text: "Qatar"
  },
  {
    id: "RE",
    text: "Réunion"
  },
  {
    id: "RO",
    text: "Romania"
  },
  {
    id: "RU",
    text: "Russian Federation"
  },
  {
    id: "RW",
    text: "Rwanda"
  },
  {
    id: "BL",
    text: "Saint Barthélemy"
  },
  {
    id: "SH",
    text: "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    id: "KN",
    text: "Saint Kitts and Nevis"
  },
  {
    id: "LC",
    text: "Saint Lucia"
  },
  {
    id: "MF",
    text: "Saint Martin (French part)"
  },
  {
    id: "PM",
    text: "Saint Pierre and Miquelon"
  },
  {
    id: "VC",
    text: "Saint Vincent and the Grenadines"
  },
  {
    id: "WS",
    text: "Samoa"
  },
  {
    id: "SM",
    text: "San Marino"
  },
  {
    id: "ST",
    text: "Sao Tome and Principe"
  },
  {
    id: "SA",
    text: "Saudi Arabia"
  },
  {
    id: "SN",
    text: "Senegal"
  },
  {
    id: "RS",
    text: "Serbia"
  },
  {
    id: "SC",
    text: "Seychelles"
  },
  {
    id: "SL",
    text: "Sierra Leone"
  },
  {
    id: "SG",
    text: "Singapore"
  },
  {
    id: "SX",
    text: "Sint Maarten (Dutch part)"
  },
  {
    id: "SK",
    text: "Slovakia"
  },
  {
    id: "SI",
    text: "Slovenia"
  },
  {
    id: "SB",
    text: "Solomon Islands"
  },
  {
    id: "SO",
    text: "Somalia"
  },
  {
    id: "ZA",
    text: "South Africa"
  },
  {
    id: "GS",
    text: "South Georgia and the South Sandwich Islands"
  },
  {
    id: "SS",
    text: "South Sudan"
  },
  {
    id: "ES",
    text: "Spain"
  },
  {
    id: "LK",
    text: "Sri Lanka"
  },
  {
    id: "SD",
    text: "Sudan"
  },
  {
    id: "SR",
    text: "Suriname"
  },
  {
    id: "SJ",
    text: "Svalbard and Jan Mayen"
  },
  {
    id: "SZ",
    text: "Swaziland"
  },
  {
    id: "SE",
    text: "Sweden"
  },
  {
    id: "CH",
    text: "Switzerland"
  },
  {
    id: "SY",
    text: "Syrian Arab Republic"
  },
  {
    id: "TW",
    text: "Taiwan, Province of China"
  },
  {
    id: "TJ",
    text: "Tajikistan"
  },
  {
    id: "TZ",
    text: "Tanzania, United Republic of"
  },
  {
    id: "TH",
    text: "Thailand"
  },
  {
    id: "TL",
    text: "Timor-Leste"
  },
  {
    id: "TG",
    text: "Togo"
  },
  {
    id: "TK",
    text: "Tokelau"
  },
  {
    id: "TO",
    text: "Tonga"
  },
  {
    id: "TT",
    text: "Trinidad and Tobago"
  },
  {
    id: "TN",
    text: "Tunisia"
  },
  {
    id: "TR",
    text: "Turkey"
  },
  {
    id: "TM",
    text: "Turkmenistan"
  },
  {
    id: "TC",
    text: "Turks and Caicos Islands"
  },
  {
    id: "TV",
    text: "Tuvalu"
  },
  {
    id: "UG",
    text: "Uganda"
  },
  {
    id: "UA",
    text: "Ukraine"
  },
  {
    id: "AE",
    text: "United Arab Emirates"
  },
  {
    id: "GB",
    text: "United Kingdom"
  },
  {
    id: "UM",
    text: "United States Minor Outlying Islands"
  },
  {
    id: "UY",
    text: "Uruguay"
  },
  {
    id: "UZ",
    text: "Uzbekistan"
  },
  {
    id: "VU",
    text: "Vanuatu"
  },
  {
    id: "VE",
    text: "Venezuela, Bolivarian Republic of"
  },
  {
    id: "VN",
    text: "Viet Nam"
  },
  {
    id: "VG",
    text: "Virgin Islands, British"
  },
  {
    id: "VI",
    text: "Virgin Islands, U.S."
  },
  {
    id: "WF",
    text: "Wallis and Futuna"
  },
  {
    id: "EH",
    text: "Western Sahara"
  },
  {
    id: "YE",
    text: "Yemen"
  },
  {
    id: "ZM",
    text: "Zambia"
  },
  {
    id: "ZW",
    text: "Zimbabwe"
  }
]

window.countriesOptions = window.countries.reduce((acc, country) => {
  acc[country.id] = country.text
  return acc
}, {})

window.states = {
  CA: [
    {
      id: "AB",
      text: "Alberta"
    },
    {
      id: "BC",
      text: "British Columbia"
    },
    {
      id: "MB",
      text: "Manitoba"
    },
    {
      id: "NB",
      text: "New Brunswick"
    },
    {
      id: "NL",
      text: "Newfoundland"
    },
    {
      id: "NS",
      text: "Nova Scotia"
    },
    {
      id: "NT",
      text: "Northwest Territories"
    },
    {
      id: "NU",
      text: "Nunavut"
    },
    {
      id: "ON",
      text: "Ontario"
    },
    {
      id: "PE",
      text: "Prince Edward Island"
    },
    {
      id: "QC",
      text: "Quebec"
    },
    {
      id: "SK",
      text: "Saskatchewan"
    },
    {
      id: "YT",
      text: "Yukon Territories"
    }
  ],
  US: [
    {
      id: "AL",
      text: "Alabama"
    },
    {
      id: "AK",
      text: "Alaska"
    },
    {
      id: "AS",
      text: "American Samoa"
    },
    {
      id: "AZ",
      text: "Arizona"
    },
    {
      id: "AR",
      text: "Arkansas"
    },
    {
      id: "CA",
      text: "California"
    },
    {
      id: "CO",
      text: "Colorado"
    },
    {
      id: "CT",
      text: "Connecticut"
    },
    {
      id: "DE",
      text: "Delaware"
    },
    {
      id: "DC",
      text: "District of Columbia"
    },
    {
      id: "FM",
      text: "Federated States of Micronesia"
    },
    {
      id: "FL",
      text: "Florida"
    },
    {
      id: "GA",
      text: "Georgia"
    },
    {
      id: "GU",
      text: "Guam"
    },
    {
      id: "HI",
      text: "Hawaii"
    },
    {
      id: "ID",
      text: "Idaho"
    },
    {
      id: "IL",
      text: "Illinois"
    },
    {
      id: "IN",
      text: "Indiana"
    },
    {
      id: "IA",
      text: "Iowa"
    },
    {
      id: "KS",
      text: "Kansas"
    },
    {
      id: "KY",
      text: "Kentucky"
    },
    {
      id: "LA",
      text: "Louisiana"
    },
    {
      id: "ME",
      text: "Maine"
    },
    {
      id: "MH",
      text: "Marshall Islands"
    },
    {
      id: "MD",
      text: "Maryland"
    },
    {
      id: "MA",
      text: "Massachusetts"
    },
    {
      id: "MI",
      text: "Michigan"
    },
    {
      id: "MN",
      text: "Minnesota"
    },
    {
      id: "MS",
      text: "Mississippi"
    },
    {
      id: "MO",
      text: "Missouri"
    },
    {
      id: "MT",
      text: "Montana"
    },
    {
      id: "NE",
      text: "Nebraska"
    },
    {
      id: "NV",
      text: "Nevada"
    },
    {
      id: "NH",
      text: "New Hampshire"
    },
    {
      id: "NJ",
      text: "New Jersey"
    },
    {
      id: "NM",
      text: "New Mexico"
    },
    {
      id: "NY",
      text: "New York"
    },
    {
      id: "NC",
      text: "North Carolina"
    },
    {
      id: "ND",
      text: "North Dakota"
    },
    {
      id: "MP",
      text: "Northern Mariana Islands"
    },
    {
      id: "OH",
      text: "Ohio"
    },
    {
      id: "OK",
      text: "Oklahoma"
    },
    {
      id: "OR",
      text: "Oregon"
    },
    {
      id: "PW",
      text: "Palau"
    },
    {
      id: "PA",
      text: "Pennsylvania"
    },
    {
      id: "PR",
      text: "Puerto Rico"
    },
    {
      id: "RI",
      text: "Rhode Island"
    },
    {
      id: "SC",
      text: "South Carolina"
    },
    {
      id: "SD",
      text: "South Dakota"
    },
    {
      id: "TN",
      text: "Tennessee"
    },
    {
      id: "TX",
      text: "Texas"
    },
    {
      id: "UT",
      text: "Utah"
    },
    {
      id: "VT",
      text: "Vermont"
    },
    {
      id: "VI",
      text: "Virgin Islands"
    },
    {
      id: "VA",
      text: "Virginia"
    },
    {
      id: "WA",
      text: "Washington"
    },
    {
      id: "WV",
      text: "West Virginia"
    },
    {
      id: "WI",
      text: "Wisconsin"
    },
    {
      id: "WY",
      text: "Wyoming"
    }
  ]
}