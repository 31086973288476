import Vue from 'vue'
import Vuex from 'vuex'
import system from '../system/store'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...system
  }
})