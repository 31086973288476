<template>
<div>
  <div class="step-form">
    <h3>New Provider</h3>
    <ul class="step-tabs st4">
      <li v-for="(stepLabel, stepIndex) in steps" :class="{past: step>stepIndex, active: step==stepIndex}">
        <a href="">{{ stepLabel  }}</a>
      </li>
    </ul>
    <ul class="tab-content">
      <li v-if="step==0" class="active">
        <div class="tab-form-row">
          <h4>Provider Information</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Provider Name:</label>
              <input type="text" placeholder="Provider Name" />
            </div>
            <div class="cof-field">
              <label>Provider Contact Name:</label>
              <input placeholder="Provider Contact Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Provider Contact Email:</label>
              <input placeholder="Provider Contact Email" type="text">
            </div>
            <div class="cof-field">
              <label>Provider Contact Phone:</label>
              <input placeholder="Provider Contact Phone" type="text">
            </div>
            <div class="cof-field">
              <label>Provider Support Email:</label> 
              <input placeholder="Provider Support Email" type="text">
            </div>
            <div class="cof-field">
              <label>Provider Support Phone:</label> 
              <input placeholder="Provider Support Phone" type="text">
            </div>
          </div>
          <!--<div class="cof-row">
            <div class="cof-field">
              <label>Organization Address:</label>
              <input placeholder="Organization Address" type="text"> 
            </div>
            <div class="cof-field">
              <label>Organization Address 2:</label>
              <input placeholder="Organization Address 2" type="text"> 
            </div>
            <div class="cof-field">
              <label>Organization City:</label>
              <input placeholder="Organization City" type="text">
            </div>
            <div class="cof-field">
              <label>Organization Province/State:</label>
              <input placeholder="Organization Province/State" type="text">
            </div>
            <div class="cof-field">
              <label>Organization Postal/ZIP:</label> 
              <input placeholder="Organization Postal/ZIP" type="text">
            </div>
            <div class="cof-field">
              <label>Organization Country:</label> 
              <select>
                <option>Add countries here</option>
              </select>
            </div>
          </div>
          <div class="cof-row">
            <div class="cof-field">
              <label>Organization Support Phone:</label>
              <input placeholder="Organization Support Phone" type="text"> 
            </div>
            <div class="cof-field">
              <label>Organization Support Email:</label>
              <input placeholder="Organization Support Email" type="text"> 
            </div>
            
          </div>-->
        </div>
      </li>
      <li v-else-if="step==1" class="active">
        <div class="tab-form-row tfr5x">
          <h4>Provider Adapters</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Adapter Type:</label> 
              <select>
                <option>Alerts</option>
                <option>Authentication</option>
                <option>Compliance</option>
                <option>Fraud</option>
                <option>Processor</option>
              </select>
            </div>
            <div class="cof-field">
              <label>Adapter Name:</label>
              <input placeholder="Adapter Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Adapter Version:</label>
              <input placeholder="Adapter Version" type="text"> 
            </div>
            <div class="cof-field">
              <label>Username:</label>
              <input placeholder="Username" type="text"> 
            </div>
            <div class="cof-field">
              <label>Password:</label>
              <input placeholder="Password" type="password"> 
            </div>
            <div class="cof-field">
              <label>KEY:</label>
              <input placeholder="KEY" type="text"> 
            </div>
          </div>
        </div>
        <div class="tab-form-row tfr5x">
          <!--<h4>Secondary Contact</h4>-->
          <div class="cof-row">
            <div class="cof-field note">
              <label>Note:</label>
              <textarea placeholder="Note"></textarea> 
            </div>
            
          </div>
        </div>
        <!--<div class="tab-form-row tfr5">
          <h4>Technical Contact</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Title:</label>
              <input placeholder="Title" type="text"> 
            </div>
            <div class="cof-field">
              <label>First Name:</label>
              <input placeholder="First Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Last Name:</label>
              <input placeholder="Last Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Email:</label>
              <input placeholder="Email" type="text"> 
            </div>
            <div class="cof-field">
              <label>Phone:</label>
              <input placeholder="Phone" type="text"> 
            </div>
          </div>
        </div>
        <div class="tab-form-row tfr5">
          <h4>Support Contact</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Title:</label>
              <input placeholder="Title" type="text"> 
            </div>
            <div class="cof-field">
              <label>First Name:</label>
              <input placeholder="First Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Last Name:</label>
              <input placeholder="Last Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Email:</label>
              <input placeholder="Email" type="text"> 
            </div>
            <div class="cof-field">
              <label>Phone:</label>
              <input placeholder="Phone" type="text"> 
            </div>
          </div>
        </div>
        <div class="tab-form-row tfr5">
          <h4>Billing Contact</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Title:</label>
              <input placeholder="Title" type="text"> 
            </div>
            <div class="cof-field">
              <label>First Name:</label>
              <input placeholder="First Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Last Name:</label>
              <input placeholder="Last Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Email:</label>
              <input placeholder="Email" type="text"> 
            </div>
            <div class="cof-field">
              <label>Phone:</label>
              <input placeholder="Phone" type="text"> 
            </div>
          </div>
        </div>-->
      </li>
      <li v-else-if="step==2" class="active">
      
      </li>
      <li v-else-if="step==3" class="active">
      </li>
      <li v-else-if="step==4" class="active">
      </li>
    </ul>
    <div class="step-nav">
      <a href="" class="prev" v-if="step" v-on:click.prevent="prevStep">Previous step</a>
      <a href="" class="cancel" v-on:click.prevent="cancel">Cancel</a>
      <a href="" class="next" v-if="step<3" v-on:click.prevent="nextStep">Next step</a>
      <a href="" class="fin" v-else-if="step==3" v-on:click.prevent="finish">Finish</a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data: ()=>({
    steps: ["Provider Information", "Provider Adapters", "Provider Settings", "Provider Integrations"],
    step: 0,
    form: {
      orgType: null,
      
    },
  }),
  methods: {
    prevStep() {
      this.step--;
    },
    nextStep() {
      this.step++;
    },
    finish() {
      // TODO
    },
    cancel() {
      history.go(-1);
    },
  },
}
</script>
