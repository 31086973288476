var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { staticClass: "content-filters" },
      [
        _c("basic-form", [
          _c("h4", [_vm._v("Connex Connection Manager")]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "cof-row",
              staticStyle: { "margin-bottom": "70px" }
            },
            [
              _c("mnb-select", {
                attrs: {
                  options: _vm.yesNoOptions,
                  disabled: !_vm.updateConnex,
                  label: "Connex Connect"
                },
                model: {
                  value: _vm.connexForm.connex_connect,
                  callback: function($$v) {
                    _vm.$set(_vm.connexForm, "connex_connect", $$v)
                  },
                  expression: "connexForm.connex_connect"
                }
              }),
              _vm._v(" "),
              _c("mnb-input", {
                attrs: { disabled: !_vm.updateConnex, label: "Version" },
                model: {
                  value: _vm.connexForm.version,
                  callback: function($$v) {
                    _vm.$set(_vm.connexForm, "version", $$v)
                  },
                  expression: "connexForm.version"
                }
              }),
              _vm._v(" "),
              _c("mnb-input", {
                attrs: { disabled: !_vm.updateConnex, label: "Username" },
                model: {
                  value: _vm.connexForm.username,
                  callback: function($$v) {
                    _vm.$set(_vm.connexForm, "username", $$v)
                  },
                  expression: "connexForm.username"
                }
              }),
              _vm._v(" "),
              _c("mnb-input", {
                attrs: { disabled: !_vm.updateConnex, label: "Key" },
                model: {
                  value: _vm.connexForm.code,
                  callback: function($$v) {
                    _vm.$set(_vm.connexForm, "code", $$v)
                  },
                  expression: "connexForm.code"
                }
              }),
              _vm._v(" "),
              _c("mnb-select", {
                attrs: {
                  options: _vm.statusOptions,
                  disabled: !_vm.updateConnex,
                  label: "Status"
                },
                model: {
                  value: _vm.connexForm.status,
                  callback: function($$v) {
                    _vm.$set(_vm.connexForm, "status", $$v)
                  },
                  expression: "connexForm.status"
                }
              }),
              _vm._v(" "),
              _c("mnb-textarea", {
                ref: "notesTextarea",
                staticStyle: { "flex-grow": "1" },
                attrs: {
                  label: "Notes",
                  disabled: !_vm.updateConnex,
                  classes: { connexNote: true }
                },
                on: { input: _vm.mixin_autoResize_resize },
                model: {
                  value: _vm.connexForm.notes,
                  callback: function($$v) {
                    _vm.$set(_vm.connexForm, "notes", $$v)
                  },
                  expression: "connexForm.notes"
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "cof-field mobinl" }, [
                _c("label", [_vm._v(" ")]),
                _vm._v(" "),
                !_vm.updateConnex
                  ? _c(
                      "button",
                      {
                        staticClass: "main",
                        staticStyle: {
                          "min-width": "100px",
                          "margin-left": "20px",
                          "margin-right": "20px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateConnexConnection()
                          }
                        }
                      },
                      [_vm._v("Update")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "main save-connex",
                        staticStyle: {
                          "min-width": "100px",
                          "margin-left": "20px",
                          "background-color": "#67c23a",
                          "margin-right": "20px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.saveConnex()
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "cof-field mobinl" }, [
                _c("label", [_vm._v(" ")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "second",
                    on: {
                      click: function($event) {
                        return _vm.resetFieldsConnex()
                      }
                    }
                  },
                  [_vm._v("Reset Fields")]
                )
              ])
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }