<template>
  <div>
    <section class="content-filters">
      <basic-form>
        <h4>Connex Connection Manager</h4>
        <div class="cof-row" style="margin-bottom: 70px;">
          <mnb-select v-model="connexForm.connex_connect" :options="yesNoOptions" :disabled="!updateConnex" label="Connex Connect" />
          <mnb-input v-model="connexForm.version" :disabled="!updateConnex" label="Version" />
          <mnb-input v-model="connexForm.username" :disabled="!updateConnex" label="Username" />
          <mnb-input v-model="connexForm.code" :disabled="!updateConnex" label="Key" />
          <mnb-select v-model="connexForm.status" :options="statusOptions" :disabled="!updateConnex" label="Status" />
          <mnb-textarea
            v-model="connexForm.notes"
            label="Notes"
            :disabled="!updateConnex"
            @input="mixin_autoResize_resize" 
            :classes="{ 'connexNote': true}"
            ref="notesTextarea"
            style="flex-grow: 1;"/>
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button v-if="!updateConnex" class="main" @click="updateConnexConnection()" style="min-width: 100px; margin-left: 20px;margin-right: 20px;">Update</button>
            <button v-else class="main save-connex" @click="saveConnex()" style="min-width: 100px; margin-left: 20px; background-color: #67c23a;margin-right: 20px;">Save</button>
          </div>
          <div class="cof-field mobinl">
            <label>&nbsp;</label>
            <button class="second" @click="resetFieldsConnex()">Reset Fields</button>
          </div>    
        </div>
      </basic-form>
    </section>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import mixinAutoResize from "./components/ui/forms/autoresize.js"

import BasicForm from './components/ui/forms/Form'
import MnbInput from './components/ui/forms/Input'
import MnbSelect from './components/ui/forms/Select'
import Popup from './popup'
import MnbTextarea from './components/ui/forms/Textarea'

export default {
  name: 'integrations-management',

  mixins: [mixinAutoResize],

  components: {
    BasicForm,
    MnbInput,
    MnbSelect,
    Popup,
    MnbTextarea
  },

  data () {
    return {
      connexForm: {
        connex_connect: null,
        version: null,
        username: null,
        code: null,
        status: null,
        notes: null
      },
      updateConnex: false,
      statusOptions: [
        {
          id: 'Review',
          text: 'Review'
        },
        {
          id: 'Testing',
          text: 'Testing'
        },
        {
          id: 'Active',
          text: 'Active'
        },
        {
          id: 'Disabled',
          text: 'Disabled'
        }
      ],
      yesNoOptions: [
        {
          id: 1,
          text: 'Yes'
        },
        {
          id: 0,
          text: 'No'
        },
      ]
    }
  },

  async created () {
    this.connexForm = (await api.get("Connex", 1)).item;
  },

  watch: {
    'connexForm.notes' (value) {
      if (value) {
        this.$refs.notesTextarea.$el.children[1].value = value
        this.$el.getElementsByTagName('textarea')[0].style.height = `${this.$el.getElementsByTagName('textarea')[0].scrollHeight}px`
      } else {
        this.$el.getElementsByTagName('textarea')[0].style.height = '29px'
      }
    },
  },

  methods: {
    async saveConnex () {
      try {
        const payload = {
          connex_connect: this.connexForm.connex_connect,
          version: this.connexForm.version,
          username: this.connexForm.username,
          code: this.connexForm.code,
          status: this.connexForm.status,
          notes: this.connexForm.notes
        }
        
        await api.update("Connex",1, payload, true)
        this.updateConnex = false
      } catch {
      }
    },

    resetFieldsConnex() {
      Object.keys(this.connexForm).map(item => this.connexForm[item] = null)
    },

    updateConnexConnection() {
      this.updateConnex = true
    }
  },
}
</script>
