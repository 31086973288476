<template>
  <div>
    <template v-if="!open">
      <div class="content-filters">
        <basic-form>
          <h4>Identification Information</h4>
          <div class="cof-row">
            <mnb-input v-model="form.name" label="System Name" :disabled="!updateInformations"/>
            <mnb-input v-model="form.company_name" label="System Company Name" :disabled="!updateInformations"/>
            <mnb-input v-model="form.dba_name" label="System DBA Name" :disabled="!updateInformations"/>
            <mnb-input v-model="form.url" label="System URL" :disabled="!updateInformations"/>
          </div>

          <h4 class="pt-2">Address Information</h4>
          <div class="cof-row">
            <mnb-input v-model="form.address" label="System Address" :disabled="!updateInformations"/>
            <mnb-input v-model="form.city" label="System City" :disabled="!updateInformations"/>
            <mnb-input v-model="form.postal" label="System Postal Code/Zip" :disabled="!updateInformations"/>
            <mnb-select v-model="form.country" :options="countryOptions" label="System Country" :disabled="!updateInformations"/>
            <mnb-select v-model="form.state" :disabled="!countryHasState || !updateInformations" :options="stateOptions" label="System Province/State" />
          </div>

          <h4 class="pt-2">Contact Information</h4>
          <div class="cof-row">
            <mnb-input v-model="form.phone" label="System Main Phone" :disabled="!updateInformations"/>
            <mnb-input v-model="form.email" label="System Main Email" :disabled="!updateInformations"/>
            <mnb-input v-model="form.main_website" label="System Main Website" :disabled="!updateInformations"/>
            <mnb-input v-model="form.support_phone" label="System Support Phone" :disabled="!updateInformations"/>
            <mnb-input v-model="form.support_email" label="System Support Email" :disabled="!updateInformations"/>
          </div>

          <h4 class="pt-2">Communications Information</h4>
          <div class="cof-row">
            <mnb-input v-model="form.mail_from_name" label="System Mail From Name" :disabled="!updateInformations"/>
            <mnb-input v-model="form.mail_from_email" label="System Mail From Email Address" :disabled="!updateInformations"/>
          </div>

          <h4 class="pt-2">Display Text Information</h4>
          <div class="cof-row">
            <mnb-input v-model="form.login_text" label="System Login Text" :disabled="!updateInformations"/>
          </div>

          <h4 class="pt-2">Design Information</h4>
          <div class="cof-row">
            <mnb-upload-input v-model="logo" label="System Logo" :disabled="!updateInformations"/>
            <mnb-upload-input v-model="menuLogo" label="System Menu Logo" :disabled="!updateInformations"/>
            <mnb-upload-input v-model="emailLogo" label="System Email Logo" :disabled="!updateInformations"/>
          </div>

          <h4 class="pt-2">Auto Send Information</h4>
          <div class="cof-row">
            <mnb-select v-model="form.reg_email" :options="yesNoOptions" label="Registration Email" :disabled="!updateInformations"/>
            <mnb-select v-model="form.welcome_email" :options="yesNoOptions" label="Activation/Welcome Email" :disabled="!updateInformations"/>
            <mnb-select v-model="form.transaction_confirm" :options="yesNoOptions" label="Transaction Submit Confirm" :disabled="!updateInformations"/>
            <mnb-select v-model="form.statements" :options="yesNoOptions" label="Statements" :disabled="!updateInformations"/>
            <mnb-select v-model="form.update_settings_services_email" :options="yesNoOptions" label="Updated Settings/Services Email" :disabled="!updateInformations"/>
          </div>

          <div class="cof-row">
            <div v-if="updateInformations" class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="save()">Submit</button>
            </div>
            <div v-else class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="main" @click="updateInformations = true">Update</button>
            </div>
            <div class="cof-field mobinl">
              <label>&nbsp;</label>
              <button class="second" @click="resetFields()">Reset Fields</button>
            </div>
          </div>
        </basic-form>
      </div>
    </template>
    <section class="popup" :class="{open: open}">
      <div class="pop-inner" :style="{ maxWidth: `400px` }">
        <div class="pop-box">
          <h2>Please Enter Admin PIN to Continue</h2>
          <div class="pf-row">
            <div class="pf-field">
              <input
                v-model="pin"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type = "number"
                maxlength = "4"
                class="pin-code"
              />
            </div>
          </div>
          <div class="pf-row">
            <div class="pf-field">
              <template v-if="pinFailed">Invalid PIN</template>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn" @click.prevent="cancelPin()">Cancel</a>
            <button class="pop-btn" @click="checkPin()">Confirm</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BasicForm from './components/ui/forms/Form'
import MnbInput from './components/ui/forms/Input'
import MnbSelect from './components/ui/forms/Select'
import MnbUploadInput from './components/ui/forms/UploadInput'

export default {
  components: {
    MnbInput,
    BasicForm,
    MnbSelect,
    MnbUploadInput
  },

  data () {
    return {
      form: {
        name: null,
        company_name: null,
        dba_name: null,
        url: null,

        address: null,
        city: null,
        state: null,
        postal: null,
        country: null,

        phone: null,
        email: null,
        support_phone: null,
        main_website: null,
        support_email: null,

        mail_from_name: null,
        mail_from_email: null,

        login_text: null,

        logo: null,
        menu_logo: null,
        email_logo: null,

        reg_email: null,
        welcome_email: null,
        transaction_confirm: null,
        statements: null,
        update_settings_services_email: null
      },

      logo: null,
      menuLogo: null,
      emailLogo: null,

      updateInformations: false,

      yesNoOptions: [
        {
          id: true,
          text: 'Yes'
        },
        {
          id: false,
          text: 'No'
        },
      ],

      enableDisableOptions: [
        {
          id: true,
          text: 'Enable'
        },
        {
          id: false,
          text: 'Disable'
        },
      ],

      pin: '',
      pinFailed: false,
      open: false,
    }
  },

  computed: {
    countryOptions () {
      return window.countries
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.country)) ? window.states[this.form.country].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    },

    countryHasState () {
      return ['CA', 'US'].includes(this.form.country)
    },

    cancelPin() {
      window.history.back()
    },

    async checkPin() {
      const action = (await api.action("Pin", "new", "CheckPin", { pin: this.pin })).action
      if(action.pin) {
        this.pinFailed = false
        this.open = false
      }else{
        this.pinFailed = true
      }
    },
  },

  watch: {
    'form.country' (value) {
      this.form.state = null
    }
  },

  created () {
    this.$store.commit('system/setSingle', window.appData.system)
    this.form = JSON.parse(JSON.stringify(window.appData.system))
    this.$nextTick(() => (this.form.state = window.appData.system.state))
  },

  methods: {
    async save () {

      try {
        const payload = { ...this.form }
        await this.$store.dispatch('system/update', { id: 1, payload: this.form })

        if (this.logo) await this.$store.dispatch('system/upload', { id: 1, file: this.logo })
        if (this.menuLogo) await this.$store.dispatch('system/upload', { id: 1, file: this.menuLogo, params: { field: 'menu_logo' } })
        if (this.emailLogo) await this.$store.dispatch('system/upload', { id: 1, file: this.emailLogo, params: { field: 'email_logo' } })

        this.logo = null
        this.emailLogo = null
        this.updateInformations = false
        if (this.menuLogo) location.reload()
      } catch (e) {
      }
    },

    resetFields () {
        this.form = {
          name: null,
          company_name: null,
          dba_name: null,
          url: null,

          address: null,
          city: null,
          state: null,
          postal: null,
          country: null,

          phone: null,
          email: null,
          support_phone: null,
          main_website: null,
          support_email: null,

          mail_from_name: null,
          mail_from_email: null,

          login_text: null,

          logo: null,
          menu_logo: null,
          email_logo: null,

          reg_email: null,
          welcome_email: null,
          transaction_confirm: null,
          statements: null,
          update_settings_services_email: null
        }
    }
  }
}
</script>
<style type="scss" scoped>
  .content-filters h4 {
   margin-top: 10px;
  }
</style>