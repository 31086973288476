<template>
<div>
  <div class="step-form">
    <h3>New Organization</h3>
    <ul class="step-tabs">
      <li v-for="(stepLabel, stepIndex) in steps" :class="{past: step>stepIndex, active: step==stepIndex}">
        <a href="">{{ stepLabel  }}</a>
      </li>
    </ul>
    <ul class="tab-content">
      <li v-if="step==0" class="active">
        <div class="tab-form-row">
          <h4>Organization</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Organization Type:</label>
              <select v-model="form.orgType">
                <option>Acquirer</option>
                <option>Enterprise Merchant</option>
                <option>Financial</option>
                <option>Fintech</option>
                <option>ISO/MSP</option>
                <option>Merchant</option>
                <option>MSB</option>
                <option>Payfac</option>
                <option>Processor</option>
                <option>PSP/IPSP</option>
                <option>TPPP</option>
                <option>VAR</option>
              </select>
            </div>
            <div class="cof-field">
              <label>Organization Name:</label>
              <input placeholder="Organization Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Organization DBA:</label>
              <input placeholder="Organization DBA" type="text">
            </div>
            <div class="cof-field">
              <label>Organization Nickname:</label>
              <input placeholder="Organization Nickname" type="text">
            </div>
            <div class="cof-field">
              <label>Organization Phone:</label> 
              <input placeholder="Organization Phone" type="text">
            </div>
            <div class="cof-field">
              <label>Organization Email:</label> 
              <input placeholder="Organization Email" type="text">
            </div>
          </div>
          <div class="cof-row">
            <div class="cof-field">
              <label>Organization Address:</label>
              <input placeholder="Organization Address" type="text"> 
            </div>
            <div class="cof-field">
              <label>Organization Address 2:</label>
              <input placeholder="Organization Address 2" type="text"> 
            </div>
            <div class="cof-field">
              <label>Organization City:</label>
              <input placeholder="Organization City" type="text">
            </div>
            <div class="cof-field">
              <label>Organization Province/State:</label>
              <input placeholder="Organization Province/State" type="text">
            </div>
            <div class="cof-field">
              <label>Organization Postal/ZIP:</label> 
              <input placeholder="Organization Postal/ZIP" type="text">
            </div>
            <div class="cof-field">
              <label>Organization Country:</label> 
              <select>
                <option>Add countries here</option>
              </select>
            </div>
          </div>
          <div class="cof-row">
            <div class="cof-field">
              <label>Organization Support Phone:</label>
              <input placeholder="Organization Support Phone" type="text"> 
            </div>
            <div class="cof-field">
              <label>Organization Support Email:</label>
              <input placeholder="Organization Support Email" type="text"> 
            </div>
            
          </div>
        </div>
      </li>
      <li v-else-if="step==1" class="active">
        <div class="tab-form-row tfr5">
          <h4>Primary Contact</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Title:</label>
              <input placeholder="Title" type="text"> 
            </div>
            <div class="cof-field">
              <label>First Name:</label>
              <input placeholder="First Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Last Name:</label>
              <input placeholder="Last Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Email:</label>
              <input placeholder="Email" type="text"> 
            </div>
            <div class="cof-field">
              <label>Phone:</label>
              <input placeholder="Phone" type="text"> 
            </div>
          </div>
        </div>
        <div class="tab-form-row tfr5">
          <h4>Secondary Contact</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Title:</label>
              <input placeholder="Title" type="text"> 
            </div>
            <div class="cof-field">
              <label>First Name:</label>
              <input placeholder="First Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Last Name:</label>
              <input placeholder="Last Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Email:</label>
              <input placeholder="Email" type="text"> 
            </div>
            <div class="cof-field">
              <label>Phone:</label>
              <input placeholder="Phone" type="text"> 
            </div>
          </div>
        </div>
        <div class="tab-form-row tfr5">
          <h4>Technical Contact</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Title:</label>
              <input placeholder="Title" type="text"> 
            </div>
            <div class="cof-field">
              <label>First Name:</label>
              <input placeholder="First Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Last Name:</label>
              <input placeholder="Last Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Email:</label>
              <input placeholder="Email" type="text"> 
            </div>
            <div class="cof-field">
              <label>Phone:</label>
              <input placeholder="Phone" type="text"> 
            </div>
          </div>
        </div>
        <div class="tab-form-row tfr5">
          <h4>Support Contact</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Title:</label>
              <input placeholder="Title" type="text"> 
            </div>
            <div class="cof-field">
              <label>First Name:</label>
              <input placeholder="First Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Last Name:</label>
              <input placeholder="Last Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Email:</label>
              <input placeholder="Email" type="text"> 
            </div>
            <div class="cof-field">
              <label>Phone:</label>
              <input placeholder="Phone" type="text"> 
            </div>
          </div>
        </div>
        <div class="tab-form-row tfr5">
          <h4>Billing Contact</h4>
          <div class="cof-row">
            <div class="cof-field">
              <label>Title:</label>
              <input placeholder="Title" type="text"> 
            </div>
            <div class="cof-field">
              <label>First Name:</label>
              <input placeholder="First Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Last Name:</label>
              <input placeholder="Last Name" type="text"> 
            </div>
            <div class="cof-field">
              <label>Email:</label>
              <input placeholder="Email" type="text"> 
            </div>
            <div class="cof-field">
              <label>Phone:</label>
              <input placeholder="Phone" type="text"> 
            </div>
          </div>
        </div>
      </li>
      <li v-else-if="step==2" class="active">
      </li>
      <li v-else-if="step==3" class="active">
      </li>
      <li v-else-if="step==4" class="active">
      </li>
    </ul>
    <div class="step-nav">
      <a href="" class="prev" v-if="step" v-on:click.prevent="prevStep">Previous step</a>
      <a href="" class="cancel" v-on:click.prevent="cancel">Cancel</a>
      <a href="" class="next" v-if="step<4" v-on:click.prevent="nextStep">Next step</a>
      <a href="" class="fin" v-else-if="step==4" v-on:click.prevent="finish">Finish</a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data: ()=>({
    steps: ["General", "Contacts", "Settings", "Policies", "Pricing"],
    step: 0,
    form: {
      orgType: null,
      
    },
  }),
  methods: {
    prevStep() {
      this.step--;
    },
    nextStep() {
      this.step++;
    },
    finish() {
      // TODO
    },
    cancel() {
      history.go(-1);
    },
  },
}
</script>
