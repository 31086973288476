var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section", [
        _c(
          "em",
          {
            staticClass: "pop-show add-emp",
            on: {
              click: function($event) {
                return _vm.openPopup()
              }
            }
          },
          [_vm._v("+ New Employee")]
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "content-tlist" }, [
        _c(
          "table",
          { staticClass: "content-table" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.items, function(item, index) {
              return _c("tr", [
                _c("td", [
                  _c("span", [_vm._v("Emp ID")]),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.openEmp(item)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.id))]
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("First Name")]),
                  _vm._v(_vm._s(item.firstName))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Last Name")]),
                  _vm._v(_vm._s(item.lastName))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Email")]),
                  _vm._v(_vm._s(item.email))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("span", [_vm._v("Role")]),
                  _c("em", [_vm._v(_vm._s(item.role))])
                ]),
                _vm._v(" "),
                _vm._m(1, true)
              ])
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "popup", attrs: { id: "employeePopup" } }, [
        _c("div", { staticClass: "pop-inner" }, [
          _c("div", { staticClass: "pop-box" }, [
            _c("a", { staticClass: "close" }),
            _vm._v(" "),
            _c("h2", [_vm._v("Create New Employee")]),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass: "pop-form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.createItem()
                  }
                }
              },
              [
                _c("div", { staticClass: "pf-row split" }, [
                  _c("div", { staticClass: "pf-field" }, [
                    _c("label", [_vm._v("Role")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newItem.role,
                            expression: "newItem.role"
                          }
                        ],
                        attrs: { required: "" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.newItem,
                              "role",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "Administrator" } }, [
                          _vm._v("Administrator")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Operations" } }, [
                          _vm._v("Operations")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Developer" } }, [
                          _vm._v("Developer")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Support" } }, [
                          _vm._v("Support")
                        ])
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pf-row split" }, [
                  _c("div", { staticClass: "pf-field" }, [
                    _c("label", [_vm._v("First Name")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newItem.firstName,
                          expression: "newItem.firstName"
                        }
                      ],
                      attrs: { type: "text", value: "firstName", required: "" },
                      domProps: { value: _vm.newItem.firstName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.newItem,
                            "firstName",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pf-field" }, [
                    _c("label", [_vm._v("Last Name")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newItem.lastName,
                          expression: "newItem.lastName"
                        }
                      ],
                      attrs: { type: "text", value: "lastName", required: "" },
                      domProps: { value: _vm.newItem.lastName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.newItem, "lastName", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pf-row split" }, [
                  _c("div", { staticClass: "pf-field" }, [
                    _c("label", [_vm._v("Email")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newItem.email,
                          expression: "newItem.email"
                        }
                      ],
                      attrs: { type: "email", value: "email", required: "" },
                      domProps: { value: _vm.newItem.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.newItem, "email", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pf-field" }, [
                    _c("label", [_vm._v("Password")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newItem.password,
                          expression: "newItem.password"
                        }
                      ],
                      attrs: { type: "text", value: "password", required: "" },
                      domProps: { value: _vm.newItem.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.newItem, "password", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _vm._m(2)
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "popup",
        {
          attrs: { open: _vm.empPop },
          on: {
            "update:open": function($event) {
              _vm.empPop = $event
            }
          }
        },
        [
          _c("h2", [_vm._v("View Employee")]),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "pop-form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.update()
                }
              }
            },
            [
              _c("div", { staticClass: "pf-row split" }, [
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("Employee ID")]),
                  _vm._v(
                    "\n          " + _vm._s(_vm.updateItem.id) + "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("Role")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.updateItem.role,
                          expression: "updateItem.role"
                        }
                      ],
                      attrs: { required: "", disabled: !_vm.editStatus },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.updateItem,
                            "role",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "Administrator" } }, [
                        _vm._v("Administrator")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Operations" } }, [
                        _vm._v("Operations")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Developer" } }, [
                        _vm._v("Developer")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Support" } }, [
                        _vm._v("Support")
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row split" }, [
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("First Name")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.updateItem.firstName,
                        expression: "updateItem.firstName"
                      }
                    ],
                    attrs: {
                      type: "text",
                      value: "firstName",
                      required: "",
                      disabled: !_vm.editStatus
                    },
                    domProps: { value: _vm.updateItem.firstName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.updateItem,
                          "firstName",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("Last Name")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.updateItem.lastName,
                        expression: "updateItem.lastName"
                      }
                    ],
                    attrs: {
                      type: "text",
                      value: "lastName",
                      required: "",
                      disabled: !_vm.editStatus
                    },
                    domProps: { value: _vm.updateItem.lastName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.updateItem,
                          "lastName",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row split" }, [
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("Email")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.updateItem.email,
                        expression: "updateItem.email"
                      }
                    ],
                    attrs: {
                      type: "email",
                      value: "email",
                      required: "",
                      disabled: !_vm.editStatus
                    },
                    domProps: { value: _vm.updateItem.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.updateItem, "email", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "pf-field" }, [
                  _c("label", [_vm._v("Password Reset")]),
                  _vm._v(" "),
                  _c("em", { staticClass: "pop-show generate-btn" }, [
                    _vm._v("Reset Password")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pf-row buttons" }, [
                _c(
                  "a",
                  {
                    staticClass: "pop-btn close-popup",
                    on: {
                      click: function($event) {
                        _vm.empPop = false
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                ),
                _vm._v(" "),
                !_vm.editStatus
                  ? _c(
                      "a",
                      {
                        staticClass: "pop-btn",
                        on: {
                          click: function($event) {
                            _vm.editStatus = true
                          }
                        }
                      },
                      [_vm._v("Edit")]
                    )
                  : _c("button", { staticClass: "pop-btn" }, [_vm._v("Save")])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Emp ID")]),
      _vm._v(" "),
      _c("th", [_vm._v("First Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Last Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Email")]),
      _vm._v(" "),
      _c("th", [_vm._v("Role")]),
      _vm._v(" "),
      _c("th", [_vm._v("Options")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("span", [_vm._v("Options")]),
      _c("em", { staticStyle: { background: "#000" } }, [_vm._v("Block")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pf-row buttons" }, [
      _c("a", { staticClass: "pop-btn close-popup", attrs: { href: "" } }, [
        _vm._v("Cancel")
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "pop-btn" }, [_vm._v("Create")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }