import jQuery from 'jquery';
window.$=jQuery;

import moment from 'moment';
window.moment=moment;

import Vue from 'vue';
window.Vue=Vue;

export const EventBus = new Vue();

import './lib/jquery-ui.min';
import './lib/api';
import './lib/validation';
import './lib/filters';
import './lib/globals';

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Loading from 'vue-loading-overlay'

import 'vue-loading-overlay/dist/vue-loading.css'

Vue.use(Loading)

var VueComponents = {};

var vueComponentList = [
  require.context(".", false, /.+\.vue$/),
//   require.context("./form-fields", false, /.+\.vue$/),
];

for(let vueComponentListItem of vueComponentList)
  for(let key of vueComponentListItem.keys()) {
    let actualKey=key.replace(/\.vue|.*\//g, "");
    let actualComponent=vueComponentListItem(key).default;
    VueComponents[actualKey]=actualComponent;
    Vue.component(actualKey, actualComponent);
  }

import './lib/common';



$(function($) {
  $(".mob-handle").click(function(e) {
    e.preventDefault();
    $(".mainside").toggleClass("open");
  });
  $("#providerPopup .pop-show, #providerPopup .close-popup, #providerPopup .pop-box > .close").click(function(e) {
    e.preventDefault();
    $("#providerPopup").toggleClass("open");
  });
  $("#statusPopup .pop-show, #statusPopup .close-popup, #statusPopup .pop-box > .close").click(function(e) {
    e.preventDefault();
    $("#statusPopup").toggleClass("open");
  });
  $("#employeePopup .pop-show, #employeePopup .close-popup, #employeePopup .pop-box > .close").click(function(e) {
    e.preventDefault();
    $("#employeePopup").toggleClass("open");
  });
  $(".s-main ul li .title").click(function(e) {
    e.preventDefault();
    $(this).parent().toggleClass("open");
  });
  $(".tsm-handle").click(function(e) {
    e.preventDefault();
    $(".cof-form").toggleClass("open");
  });
  $(".cof-close").click(function(e) {
    e.preventDefault();
    $(".cof-form").toggleClass("open");
  });
  $(".ddcontrol").click(function(e) {
    e.preventDefault();
    $(".uscontrol .dropdown").toggleClass("open");
  });
  $(".mainside nav > ul > li a").click(function(e) {
    if (!$(this).parent().hasClass("open") && $(this).next("ul").length) {
      e.preventDefault();
      $(this).parent().toggleClass("open");
    }
  });
  $(".content-form input").each(function() {
    var field=$(this);
    var parent=field.parent();
    var hasData=false;
    var focused=false;
    var update=function() {
      if (hasData || focused)
        parent.addClass("filled");
      else 
        parent.removeClass("filled");
    }
    field.on("change", function() {
      hasData=!!field.val();
      update();
    }).on("focus", function() {
      focused=true;
      update();
    }).on("blur", function() {
      focused=false;
      update();
    });
    
  });
  $(".content-form select").each(function() {
    var field=$(this);
    var parent=field.parent().parent();
    var span=field.parent().find(">span");
    var hasData=false;
    var focused=false;
    var update=function() {
      if (hasData || focused)
        parent.addClass("filled");
      else 
        parent.removeClass("filled");
    }
    field.on("change", function() {
      hasData=!!field.val();
      update();
      var opt=field.find(">option[value='"+field.val()+"']");
      span.html(opt.length?opt.html():field.val());
    }).on("focus", function() {
      focused=true;
      update();
    }).on("blur", function() {
      focused=false;
      update();
    });
  });
  $(".logout").click(function(e) {
    e.preventDefault();
    var update= async function() {
      let resp = await api.logout();
      if(resp.success == true){
        document.location.href = "/";
      }
    }
    update();
  });
});
