import factory from '../../store/factory'

export default {
  system: factory('System'),
  systemMessage: factory('SystemMessage'),
  providersList: factory('ProvidersList'),
  integration: factory('Integration'),
  connex: factory('Connex'),
  pin: factory('Pin'),
  changeLog: factory('ChangeLog')
}