<template>
  <div>
    <section>
      <em class="pop-show add-emp" v-on:click="openPopup()">+ New Employee</em>
    </section>
    <section class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Emp ID</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Options</th>
        </tr>
        <tr v-for="(item, index) in items">
          <td><span>Emp ID</span><a @click="openEmp(item)">{{item.id}}</a></td>
          <td><span>First Name</span>{{item.firstName}}</td>
          <td><span>Last Name</span>{{item.lastName}}</td>
          <td><span>Email</span>{{item.email}}</td>
          <td><span>Role</span><em>{{item.role}}</em></td>
          <td><span>Options</span><em style="background:#000">Block</em></td>
        </tr>
      </table>
    </section>
    <section class="popup" id="employeePopup">
      <div class="pop-inner">
        <div class="pop-box">
          <a class="close"></a>
          <h2>Create New Employee</h2>
          <form class="pop-form" v-on:submit.prevent="createItem()">
            <div class="pf-row split">
              <div class="pf-field">
                <label>Role</label>
                <select v-model="newItem.role" required>
                  <option value="Administrator">Administrator</option>
                  <option value="Operations">Operations</option>
                  <option value="Developer">Developer</option>
                  <option value="Support">Support</option>
                </select>
              </div>
            </div>
            <div class="pf-row split">
              <div class="pf-field">
                <label>First Name</label>
                <input type="text" value="firstName" v-model="newItem.firstName" required/>
              </div>
              <div class="pf-field">
                <label>Last Name</label>
                <input type="text" value="lastName" v-model="newItem.lastName" required/>
              </div>
            </div>
            <div class="pf-row split">
              <div class="pf-field">
                <label>Email</label>
                <input type="email" value="email" v-model="newItem.email" required/>
              </div>
              <div class="pf-field">
                <label>Password</label>
                <input type="text" value="password" v-model="newItem.password" required/>
              </div>
            </div>
            <div class="pf-row buttons">
              <a href="" class="pop-btn close-popup">Cancel</a>
              <button class="pop-btn">Create</button>
            </div>
          </form>
        </div>
      </div>
    </section>
    <popup :open.sync="empPop">
      <h2>View Employee</h2>
      <form class="pop-form" v-on:submit.prevent="update()">
        <div class="pf-row split">
          <div class="pf-field">
            <label>Employee ID</label>
            {{updateItem.id}}
          </div>
          <div class="pf-field">
            <label>Role</label>
            <select v-model="updateItem.role" required :disabled="!editStatus">
              <option value="Administrator">Administrator</option>
              <option value="Operations">Operations</option>
              <option value="Developer">Developer</option>
              <option value="Support">Support</option>
            </select>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>First Name</label>
            <input type="text" value="firstName" v-model="updateItem.firstName" required  :disabled="!editStatus"/>
          </div>
          <div class="pf-field">
            <label>Last Name</label>
            <input type="text" value="lastName" v-model="updateItem.lastName" required  :disabled="!editStatus"/>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Email</label>
            <input type="email" value="email" v-model="updateItem.email" required  :disabled="!editStatus"/>
          </div>
          <div class="pf-field">
            <label>Password Reset</label>
            <em class="pop-show generate-btn">Reset Password</em>
          </div>
        </div>
        <div class="pf-row buttons">
          <a class="pop-btn close-popup" @click="empPop = false">Cancel</a>
          <a v-if="!editStatus" class="pop-btn" @click="editStatus = true">Edit</a>
          <button v-else class="pop-btn">Save</button>
        </div>
      </form>
    </popup>
  </div>
</template>

<script>
  export default {
    data: () => ({
      editing: {
        status: false,
        index: 0
      },
      items: [],
      newItem: {
        firstName: "",
        lastName: "",
        role: "",
        email: "",
        password: ""
      },
      updateItem: {
        firstName: "",
        lastName: "",
        role: "",
        email: "",
        password: ""
      },
      empPop: false,
      editStatus: false,
    }),
    methods: {
      async reload() {
        this.items = (await api.list("Employees", {}, ["id:ASC"])).items;
      },
      async createItem() {
        await api.create("Employees", this.newItem, true);
        $(".popup").toggleClass("open");
        this.reload();
        this.newItem = {
          firstName: "",
          lastName: "",
          role: "",
          email: "",
          password: ""
        }
      },

      async update() {
        await api.update("Employees", this.updateItem.id, this.updateItem, true)
        this.reload();
        this.empPop = false
        this.editStatus = false
      },

      async deleteItem(id) {
        var r = confirm("Are you sure?");
        if (r == true) {
          await api.delete("Employees", id);
          this.reload();
        } else {
          return;
        }

      },

      openPopup(){
        $(".popup").toggleClass("open");
      },

      openEmp(item) {
        this.updateItem = item
        this.empPop = true
      }
    },
    mounted() {
      this.reload();
    },
  }
</script>
<style lang="css" scoped>
  .generate-btn {
    display: inline-block;
    margin: 0 10px;
    padding: 0 10px;
    line-height: 26px;
    vertical-align: middle;
    background: #67c23a;
    border-radius: 4px;
    color: white;
  }
</style>