<template>
  <div>
    <section>
      <em class="pop-show add-emp" v-on:click.prevent="openAdd">+ New Organization</em>
    </section>
    <section class="content-filters">
      <div class="cof-box">
        <a href="" class="tsm-handle">Filters</a>
        <!--   <h4>Transaction Search</h4> -->
        <div class="cof-form">
          <a class="cof-close"></a>
          <!--    <h6>Transaction Search</h6> -->
            <div class="cof-row">
              <div class="cof-field">
                <label>Organization Name</label>
                <select v-model="form.type">
                  <!--<option value="">-</option>-->
                  <!--<option value="Alerts">Alerts</option>-->
                  <!--<option value="Authentication">Authentication</option>-->
                  <!--<option value="Compliance">Compliance</option>-->
                  <!--<option value="Fraud">Fraud</option>-->
                  <!--<option value="Processor">Processor</option>-->
                </select>
              </div>
              <div class="cof-field">
                <label>Organization Type</label>
                <select v-model="form.type">
                  <!--<option value="">-</option>-->
                  <!--<option value="Alerts">Alerts</option>-->
                  <!--<option value="Authentication">Authentication</option>-->
                  <!--<option value="Compliance">Compliance</option>-->
                  <!--<option value="Fraud">Fraud</option>-->
                  <!--<option value="Processor">Processor</option>-->
                </select>
              </div>
              <div class="cof-field">
                <label>Status</label>
                <select v-model="form.provider">
                  <option value="">-</option>
                  <!--<option v-bind:value="provider.name" v-bind:data-index="index"-->
                          <!--v-for="(provider, index) in providers">-->
                    <!--{{ provider.name }}-->
                  <!--</option>-->
                </select>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="main" @click="search()">Filter</button>
              </div>
              <div class="cof-field mobinl">
                <label>&nbsp;</label>
                <button class="second" @click="resetItem()">Reset Filters</button>
              </div>
            </div>
        </div>
      </div>
    </section>
    <section class="content-tlist">
      <table class="content-table">
        <tr>
          <th>Organization Name</th>
          <th>Organization Type</th>
          <th>Date Added</th>
          <th>Certified Date</th>
          <th>Status</th>
          <th>Options</th>
        </tr>
        <!--<tr v-for="(item, index) in data">-->
          <!--<td><span>Provider</span>-->
            <!--<template v-if="!isEdit(index)">{{item.name}}</template>-->
            <!--<input type="text" v-else v-model="item.name" />-->
          <!--</td>-->
          <!--<td><span>Type</span>-->
            <!--<template v-if="!isEdit(index)">{{item.adapterType}}</template>-->
            <!--<select v-else v-model="item.adapterType">-->
              <!--<option value="Alerts">Alerts</option>-->
              <!--<option value="Authentication">Authentication</option>-->
              <!--<option value="Compliance">Compliance</option>-->
              <!--<option value="Fraud">Fraud</option>-->
              <!--<option value="Processor">Processor</option>-->
            <!--</select>-->
          <!--</td>-->
          <!--<td><span>Adapter</span>-->
            <!--<template v-if="!isEdit(index)">{{item.adapterName}}</template>-->
            <!--<input type="text" v-else v-model="item.adapterName" />-->
          <!--</td>-->
          <!--<td><span>Version</span>-->
            <!--<template v-if="!isEdit(index)">{{item.adapterVersion}}</template>-->
            <!--<input type="text" v-else v-model="item.adapterVersion" />-->
          <!--</td>-->
          <!--<td><span>Date Added</span>-->
            <!--<template>{{item.createdAt | date('YYYY-MM-DD') }}</template>-->
          <!--</td>-->
          <!--<td><span>Certified Date</span>-->
            <!--<template v-if="!isEdit(index)">{{item.certDate}}</template>-->
            <!--<vue-englishdatepicker v-else v-model="item.certDate"/>-->
          <!--</td>-->
          <!--<td><span>Note</span>-->
            <!--<template v-if="!isEdit(index)"><div>{{item.note}}</div></template>-->
            <!--<input type="text" v-else v-model="item.note" />-->
          <!--</td>-->
          <!--<td v-on:click="openUpdateStatus(item.id, item.status)"><span>Status</span><em v-if="item.status === 'Active'">Active</em><em v-if="item.status !== 'Active'" class="c2">{{item.status}}</em></td>-->
          <!--<td><span>Options</span>-->
            <!--<template v-if="isEdit(index)">-->
              <!--<button @click="saveUpdate(index)" class="button-handle save">Save</button>-->
              <!--<button @click="cancelEdit(index)" class="button-handle cancel">Cancel</button>-->
            <!--</template>-->
            <!--<template v-else>-->
              <!--<button v-if="item.status == 'Disabled'" class="second" v-on:click="deleteItem(item.id)">Remove</button>-->
              <!--<button @click="edit(index)" class="button-handle update">Update</button>-->
            <!--</template>-->
          <!--</td>-->
        <!--</tr>-->
      </table>
    </section>
    <section class="popup" id="providerPopup">
      <div class="pop-inner">
        <div class="pop-box">
          <a class="close"></a>
          <h2>{{popupItem.provider}}</h2>
          <div class="pf-row split">
            <div class="pf-field">
              <label>{{popupItem.system}}</label>
            </div>
          </div>
          <div class="pf-row split">
            <div class="pf-field">
              <label>{{popupItem.connections}}</label>
            </div>
          </div>
          <div class="pf-row buttons">
            <a href="" class="pop-btn close-popup">Cancel</a>
          </div>
        </div>
      </div>
    </section>
    <section class="popup" id="statusPopup">
      <div class="pop-inner">
        <div class="pop-box">
          <a class="close"></a>
          <h2>Update Status</h2>
          <div class="pf-row split">
            <div class="pf-field">
              <select v-model="status">
                <option value="Review">Review</option>
                <option value="Testing">Testing</option>
                <option value="Active">Active</option>
                <option value="Disabled">Disabled</option>
              </select>
            </div>
          </div>
          <div class="pf-row buttons">
            <span class="pop-btn" v-on:click="updateStatus()">Confirm</span>
            <a href="" class="pop-btn close-popup">Cancel</a>
          </div>
        </div>
      </div>
    </section>
    <popup :open.sync="add">
      <h2>Add New Provider</h2>
      <form class="pop-form" v-on:submit.prevent="create()">
        <h3>Provider Information</h3>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Provider Name</label>
            <input type="text" v-model="newItem.name" required :disabled="editStatus"/>
          </div>
          <div class="pf-field">
            <label>Provider Contact Name</label>
            <input type="text" v-model="newItem.contactName" required :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Provider Contact Email</label>
            <input type="email" v-model="newItem.contactEmail" required :disabled="editStatus"/>
          </div>
          <div class="pf-field">
            <label>Provider Contact Phone</label>
            <input type="text" v-model="newItem.contactPhone" required :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Provider Support Email</label>
            <input type="email" v-model="newItem.supportEmail" required :disabled="editStatus"/>
          </div>
          <div class="pf-field">
            <label>Provider Support Phone</label>
            <input type="text" v-model="newItem.supportPhone" required :disabled="editStatus"/>
          </div>
        </div>
        <h3>Provider Adapters</h3>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Adapter Type</label>
            <select v-model="newItem.adapterType" required :disabled="editStatus">
              <option value="Alerts">Alerts</option>
              <option value="Authentication">Authentication</option>
              <option value="Compliance">Compliance</option>
              <option value="Fraud">Fraud</option>
              <option value="Processor">Processor</option>
            </select>
          </div>
          <div class="pf-field">
            <label>Adapter Name</label>
            <input type="text" v-model="newItem.adapterName" required :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Adapter Version</label>
            <input type="text" v-model="newItem.adapterVersion" required :disabled="editStatus"/>
          </div>
          <div class="pf-field">
            <label>Username</label>
            <input type="text" v-model="newItem.username" required :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row split">
          <div class="pf-field">
            <label>Password</label>
            <input type="text" v-model="newItem.password" required :disabled="editStatus"/>
          </div>
          <div class="pf-field">
            <label>KEY</label>
            <input type="text" v-model="newItem.code" required :disabled="editStatus"/>
          </div>
        </div>
        <div class="pf-row">
          <div class="pf-field">
            <label>Note</label>
            <input type="text"  v-model="newItem.note" :disabled="editStatus"/>
          </div>
        </div>
        <h3>Provider Settings</h3>
        <h3>Provider Integrations</h3>
        <div class="pf-row buttons">
          <a class="pop-btn close-popup" @click="add = false">Cancel</a>
          <a v-if="editStatus" class="edit pop-btn" @click="editStatus = false">Edit</a>
          <button v-else class="pop-btn">Save</button>
        </div>
      </form>
    </popup>
  </div>
</template>

<script>
  import VueEnglishdatepicker from 'vue-englishdatepicker';

  export default {
    components: {
      VueEnglishdatepicker,
    },

    data: () => ({
      editing: [],
      status: '',
      statusId: '',
      items: [],
      newItem: {
        name: "",
        contactName: "",
        contactEmail: "",
        contactPhone: "",
        supportEmail: "",
        supportPhone: "",
        adapterType: "",
        adapterName: "",
        adapterVersion: "",
        username: "",
        password: "",
        code: "",
        note: "",
      },
      popupItem: [],
      providerModules: window.providerModules,
      add: false,
      editStatus: false,

      appliedFilters: null,

      form: {
        type: null,
        provider: null,
        adapter: null
      },

      systems: null,
      providers: [],
    }),

    computed: {
      data () {
        let data = this.items || []

        if (!this.appliedFilters) return data

        if (this.appliedFilters.type) {
          data = data.filter(item => item.adapterType == this.appliedFilters.type)
        }

        if (this.appliedFilters.provider) {
          data = data.filter(item => item.name == this.appliedFilters.provider)
        }

        if (this.appliedFilters.adapter) {
          data = data.filter(item => item.adapterName == this.appliedFilters.adapter)
        }

        return data
      },
    },

    methods: {
      search () {
        this.appliedFilters = { ...this.form }
      },

      resetItem() {
        this.appliedFilters = null
        Object.keys(this.form).forEach(key => this.form[key] = null)
      },

      async reload() {
        this.items = (await api.list("Provider", {}, ["name:ASC"])).items;
        this.providers = (await api.list("Provider", {}, ["id:ASC"])).items;
      },

      async create() {
        if(this.newItem.id){
          await api.update("Provider", this.newItem.id, this.newItem, true);
        }else{
          this.newItem.createdAt = 'CURRENT_TIMESTAMP'
          this.newItem.status = 'Review'
          await api.create("Provider", this.newItem, true);
        }
        this.reload();
        this.add = false
        this.newItem = {
          name: "",
          contactName: "",
          contactEmail: "",
          contactPhone: "",
          supportEmail: "",
          supportPhone: "",
          adapterType: "",
          adapterName: "",
          adapterVersion: "",
          username: "",
          password: "",
          code: "",
          note: "",
        }
      },

      async deleteItem(id) {
        var r = confirm("Are you sure?");
        if (r == true) {
          await api.delete("Provider", id);
          this.reload();
        } else {
          return;
        }

      },

      async popupProvider(item) {
        this.newItem = item
        this.systems = (await api.list("SystemIntegrations", {"provider": item.provider}, ["provider:ASC"])).items;
        this.add = true
        this.editStatus = true
      },

      async openUpdateStatus(id, status) {
        this.statusId = id;
        this.status = status;
        $("#statusPopup").toggleClass("open");
      },

      async updateStatus() {
        await api.update("Provider", this.statusId, {"status":this.status}, true);
        $("#statusPopup").toggleClass("open");
        this.reload();
      },

      async saveUpdate (i) {
        try {
          const payload = {
            adapterType: this.items[i].adapterType,
            name: this.items[i].name,
            adapterName: this.items[i].adapterName,
            adapterVersion: this.items[i].adapterVersion,
            note: this.items[i].note,
            certDate: this.items[i].certDate
          }
          await api.update("Provider", this.items[i].id, payload, true);
          this.cancelEdit(i)
        } catch (e) {
        }
      },

      cancelEdit (i) {
        const index = this.editing.indexOf(i)
        this.editing.splice(index, 1)
      },

      isEdit (index) {
        return this.editing.includes(index)
      },

      edit(index) {
        this.editing.push(index)
      },
      
      openAdd() {
        location.href="/management/organizations/new";
      },
    },
    mounted() {
      this.reload();
    },
  }
</script>
<style lang="scss" scoped>
  h3 {
    color: #0275be;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #99aabb;
  }

  .pop-form button.main {
    background: #0480c6;
    color: white;
    min-width: 120px;
    border: 0;
    box-sizing: border-box;
    height: 29px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: bold;
  }
  .edit.pop-btn {
    margin-left: 16px;
    background: #0480c6 !important;
    border-color: transparent;
    color: white !important;
  }
  .content-table {
    th {
      text-align: center;
    }
  }
</style>
