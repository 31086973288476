var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "step-form" }, [
      _c("h3", [_vm._v("New Provider")]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "step-tabs st4" },
        _vm._l(_vm.steps, function(stepLabel, stepIndex) {
          return _c(
            "li",
            {
              class: {
                past: _vm.step > stepIndex,
                active: _vm.step == stepIndex
              }
            },
            [_c("a", { attrs: { href: "" } }, [_vm._v(_vm._s(stepLabel))])]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("ul", { staticClass: "tab-content" }, [
        _vm.step == 0
          ? _c("li", { staticClass: "active" }, [_vm._m(0)])
          : _vm.step == 1
          ? _c("li", { staticClass: "active" }, [
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2)
            ])
          : _vm.step == 2
          ? _c("li", { staticClass: "active" })
          : _vm.step == 3
          ? _c("li", { staticClass: "active" })
          : _vm.step == 4
          ? _c("li", { staticClass: "active" })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step-nav" }, [
        _vm.step
          ? _c(
              "a",
              {
                staticClass: "prev",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.prevStep($event)
                  }
                }
              },
              [_vm._v("Previous step")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "cancel",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.cancel($event)
              }
            }
          },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _vm.step < 3
          ? _c(
              "a",
              {
                staticClass: "next",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.nextStep($event)
                  }
                }
              },
              [_vm._v("Next step")]
            )
          : _vm.step == 3
          ? _c(
              "a",
              {
                staticClass: "fin",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.finish($event)
                  }
                }
              },
              [_vm._v("Finish")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tab-form-row" }, [
      _c("h4", [_vm._v("Provider Information")]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-row" }, [
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Provider Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { type: "text", placeholder: "Provider Name" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Provider Contact Name:")]),
          _vm._v(" "),
          _c("input", {
            attrs: { placeholder: "Provider Contact Name", type: "text" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Provider Contact Email:")]),
          _vm._v(" "),
          _c("input", {
            attrs: { placeholder: "Provider Contact Email", type: "text" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Provider Contact Phone:")]),
          _vm._v(" "),
          _c("input", {
            attrs: { placeholder: "Provider Contact Phone", type: "text" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Provider Support Email:")]),
          _vm._v(" "),
          _c("input", {
            attrs: { placeholder: "Provider Support Email", type: "text" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Provider Support Phone:")]),
          _vm._v(" "),
          _c("input", {
            attrs: { placeholder: "Provider Support Phone", type: "text" }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tab-form-row tfr5x" }, [
      _c("h4", [_vm._v("Provider Adapters")]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-row" }, [
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Adapter Type:")]),
          _vm._v(" "),
          _c("select", [
            _c("option", [_vm._v("Alerts")]),
            _vm._v(" "),
            _c("option", [_vm._v("Authentication")]),
            _vm._v(" "),
            _c("option", [_vm._v("Compliance")]),
            _vm._v(" "),
            _c("option", [_vm._v("Fraud")]),
            _vm._v(" "),
            _c("option", [_vm._v("Processor")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Adapter Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Adapter Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Adapter Version:")]),
          _vm._v(" "),
          _c("input", {
            attrs: { placeholder: "Adapter Version", type: "text" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Username:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Username", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Password:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Password", type: "password" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("KEY:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "KEY", type: "text" } })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tab-form-row tfr5x" }, [
      _c("div", { staticClass: "cof-row" }, [
        _c("div", { staticClass: "cof-field note" }, [
          _c("label", [_vm._v("Note:")]),
          _vm._v(" "),
          _c("textarea", { attrs: { placeholder: "Note" } })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }