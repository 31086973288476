var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "step-form" }, [
      _c("h3", [_vm._v("New Organization")]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "step-tabs" },
        _vm._l(_vm.steps, function(stepLabel, stepIndex) {
          return _c(
            "li",
            {
              class: {
                past: _vm.step > stepIndex,
                active: _vm.step == stepIndex
              }
            },
            [_c("a", { attrs: { href: "" } }, [_vm._v(_vm._s(stepLabel))])]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("ul", { staticClass: "tab-content" }, [
        _vm.step == 0
          ? _c("li", { staticClass: "active" }, [
              _c("div", { staticClass: "tab-form-row" }, [
                _c("h4", [_vm._v("Organization")]),
                _vm._v(" "),
                _c("div", { staticClass: "cof-row" }, [
                  _c("div", { staticClass: "cof-field" }, [
                    _c("label", [_vm._v("Organization Type:")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.orgType,
                            expression: "form.orgType"
                          }
                        ],
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.form,
                              "orgType",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", [_vm._v("Acquirer")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("Enterprise Merchant")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("Financial")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("Fintech")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("ISO/MSP")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("Merchant")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("MSB")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("Payfac")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("Processor")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("PSP/IPSP")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("TPPP")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("VAR")])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _vm._m(4)
                ]),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _vm._m(6)
              ])
            ])
          : _vm.step == 1
          ? _c("li", { staticClass: "active" }, [
              _vm._m(7),
              _vm._v(" "),
              _vm._m(8),
              _vm._v(" "),
              _vm._m(9),
              _vm._v(" "),
              _vm._m(10),
              _vm._v(" "),
              _vm._m(11)
            ])
          : _vm.step == 2
          ? _c("li", { staticClass: "active" })
          : _vm.step == 3
          ? _c("li", { staticClass: "active" })
          : _vm.step == 4
          ? _c("li", { staticClass: "active" })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step-nav" }, [
        _vm.step
          ? _c(
              "a",
              {
                staticClass: "prev",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.prevStep($event)
                  }
                }
              },
              [_vm._v("Previous step")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "cancel",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.cancel($event)
              }
            }
          },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _vm.step < 4
          ? _c(
              "a",
              {
                staticClass: "next",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.nextStep($event)
                  }
                }
              },
              [_vm._v("Next step")]
            )
          : _vm.step == 4
          ? _c(
              "a",
              {
                staticClass: "fin",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.finish($event)
                  }
                }
              },
              [_vm._v("Finish")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cof-field" }, [
      _c("label", [_vm._v("Organization Name:")]),
      _vm._v(" "),
      _c("input", { attrs: { placeholder: "Organization Name", type: "text" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cof-field" }, [
      _c("label", [_vm._v("Organization DBA:")]),
      _vm._v(" "),
      _c("input", { attrs: { placeholder: "Organization DBA", type: "text" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cof-field" }, [
      _c("label", [_vm._v("Organization Nickname:")]),
      _vm._v(" "),
      _c("input", {
        attrs: { placeholder: "Organization Nickname", type: "text" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cof-field" }, [
      _c("label", [_vm._v("Organization Phone:")]),
      _vm._v(" "),
      _c("input", {
        attrs: { placeholder: "Organization Phone", type: "text" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cof-field" }, [
      _c("label", [_vm._v("Organization Email:")]),
      _vm._v(" "),
      _c("input", {
        attrs: { placeholder: "Organization Email", type: "text" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cof-row" }, [
      _c("div", { staticClass: "cof-field" }, [
        _c("label", [_vm._v("Organization Address:")]),
        _vm._v(" "),
        _c("input", {
          attrs: { placeholder: "Organization Address", type: "text" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-field" }, [
        _c("label", [_vm._v("Organization Address 2:")]),
        _vm._v(" "),
        _c("input", {
          attrs: { placeholder: "Organization Address 2", type: "text" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-field" }, [
        _c("label", [_vm._v("Organization City:")]),
        _vm._v(" "),
        _c("input", {
          attrs: { placeholder: "Organization City", type: "text" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-field" }, [
        _c("label", [_vm._v("Organization Province/State:")]),
        _vm._v(" "),
        _c("input", {
          attrs: { placeholder: "Organization Province/State", type: "text" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-field" }, [
        _c("label", [_vm._v("Organization Postal/ZIP:")]),
        _vm._v(" "),
        _c("input", {
          attrs: { placeholder: "Organization Postal/ZIP", type: "text" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-field" }, [
        _c("label", [_vm._v("Organization Country:")]),
        _vm._v(" "),
        _c("select", [_c("option", [_vm._v("Add countries here")])])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cof-row" }, [
      _c("div", { staticClass: "cof-field" }, [
        _c("label", [_vm._v("Organization Support Phone:")]),
        _vm._v(" "),
        _c("input", {
          attrs: { placeholder: "Organization Support Phone", type: "text" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-field" }, [
        _c("label", [_vm._v("Organization Support Email:")]),
        _vm._v(" "),
        _c("input", {
          attrs: { placeholder: "Organization Support Email", type: "text" }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tab-form-row tfr5" }, [
      _c("h4", [_vm._v("Primary Contact")]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-row" }, [
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Title:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Title", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("First Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "First Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Last Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Last Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Email:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Email", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Phone:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Phone", type: "text" } })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tab-form-row tfr5" }, [
      _c("h4", [_vm._v("Secondary Contact")]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-row" }, [
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Title:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Title", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("First Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "First Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Last Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Last Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Email:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Email", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Phone:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Phone", type: "text" } })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tab-form-row tfr5" }, [
      _c("h4", [_vm._v("Technical Contact")]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-row" }, [
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Title:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Title", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("First Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "First Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Last Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Last Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Email:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Email", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Phone:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Phone", type: "text" } })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tab-form-row tfr5" }, [
      _c("h4", [_vm._v("Support Contact")]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-row" }, [
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Title:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Title", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("First Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "First Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Last Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Last Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Email:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Email", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Phone:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Phone", type: "text" } })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tab-form-row tfr5" }, [
      _c("h4", [_vm._v("Billing Contact")]),
      _vm._v(" "),
      _c("div", { staticClass: "cof-row" }, [
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Title:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Title", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("First Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "First Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Last Name:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Last Name", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Email:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Email", type: "text" } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cof-field" }, [
          _c("label", [_vm._v("Phone:")]),
          _vm._v(" "),
          _c("input", { attrs: { placeholder: "Phone", type: "text" } })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }