<template>
  <div class="form-page">
    <section class="form-box reg-form login-form">
      <h1 class="logo">
        <img src="assets/img/logo-login-220.png" alt="" />        
      </h1>
   <!--   <h2><span>CONNEX</span></h2> -->
      <form v-on:submit.prevent="login()">
        <div class="content-form">
          <div class="form-row form-fullw">
            <!--    <div class="filledx">
                  <label>User ID</label>
                  <input type="text" />
                </div> -->
          </div>
          <div class="form-row form-fullw">
            <div class="">
              <label>User name</label>
              <input type="text" v-model="email"/>
            </div>
          </div>
          <div class="form-row form-fullw">
            <div class="filledx">
              <label>Password</label>
              <input type="password" v-model="password"/>
            </div>
          </div>
          <div class="form-row form-center">
            <div class="">
              <button>Login</button>
            </div>
          </div>
          <div class="form-row form-fullw">
            <div class="txtc">
              <p>Forgot password? <a href="">Click here</a></p>
            </div>
          </div>
        </div>
      </form>
    </section>
    <section class="form-foot">
      <div class="ff-cont">
       <!-- <h2>Get Plugged</h2> -->
        <p>Visit our Homepage for more information on <a href="https://cardbiller.com/">CardBiller</a> services.</p>
        <p>Trouble logging in? Please contact: <a href="">support@cardbiller.com</a></p> 
        <p>Use of this site is restricted for the use of CardBiller Corp. Clients & Associates.</p>
        <p style="padding-top:2em">v1.0.0</p>
      <!--  <p class="spacer em"><a href="register-2.html">Apply - 1</a> | <a href="register-2.html">Apply - 2</a></p>      -->
      </div>
      <div class="ff-logo">
        <i></i>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      email: "",
      password: ""
    }),
    methods: {
      async login() {
        let resp = await api.login(this.email, this.password);
        if(resp.success == true){
          document.location.href = "/dashboard";
        }
//        this.reload();
//        this.newItem = {
//          email: "",
//          password: ""
//        }
      },
    },
  }
</script>
